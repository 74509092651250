@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.buttonColor {
  @include mixins.setColorNormal(transparent, currentColor);
  @include mixins.setColorHover(theme.$colorBgHover, currentColor);
  @include mixins.setColorActive(theme.$colorBgActive, currentColor);
  @include mixins.setColorDisabled(transparent, currentColor);

  &:disabled {
    opacity: 50%;
  }
}

.buttonSize {
  height: auto;
  width: auto;
}

.buttonPadding {
  padding: 8px;
  margin: -8px;
}
