@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.portal {
  position: relative;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.44);
  position: fixed;
  inset: 0px;
}

.content {
  background-color: theme.$colorBgMain;
  border-radius: 4px;
  box-shadow: theme.$shadow;
  color: theme.$colorFgMain;
  display: flex;
  flex-direction: column;
  font-family: fonts.$sans;
  left: 50%;
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;

  &.sizeSm {
    max-width: 400px;
  }

  &.sizeMd {
    max-width: 710px;
  }

  &.sizeLg {
    max-width: 936px;
  }
}
