@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

@mixin onNarrowScreen {
  @media (max-width: 1199.98px) {
    @content;
  }
}

.root {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-family: fonts.$sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 175%;
  min-height: 64px;
  padding: 8px 56px;
  position: relative;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink();
  }

  @include onNarrowScreen() {
    flex-direction: column;
    gap: 12px;
  }

  &.theme_darkBackground {
    color: colors.$white;
  }

  &.theme_lightBackground {
    color: colors.$black;
  }
}

.background {
  position: absolute;
  inset: 0;
}

.group {
  flex: 0 1 auto;
  min-width: 0;
  position: relative;
}

.space {
  position: relative;
  flex: 1 0 0;
  min-width: 12px;

  @include onNarrowScreen() {
    display: none;
  }
}

.separator {
  position: relative;
  flex: 0 1 auto;
  min-width: 0;

  &.mx_12px {
    margin-left: 12px;
    margin-right: 12px;
  }

  &.w_20px {
    width: 20px;
  }

  @include onNarrowScreen() {
    display: none;
  }
}

.close {
  position: relative;
  flex: 0 0 auto;

  @include onNarrowScreen() {
    position: absolute;
    top: 16px;
    right: 24px;
  }
}

.leftArrow {
  display: flex;
  position: absolute;
  flex: 0 0 auto;
  top: 50%;
  left: 28px;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

.rightArrow {
  display: flex;
  position: absolute;
  flex: 0 0 auto;
  top: 50%;
  right: 28px;
  transform: translate(+50%, -50%);
  width: auto;
  height: auto;
}
