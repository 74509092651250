@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.title {
  font-family: fonts.$serif;
  font-size: 24px;
  line-height: 125%;
  font-weight: 500;
  color: theme.$colorFgMain;
}

.message {
  font-family: fonts.$sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: theme.$colorFgMain;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.icon {
  font-size: 64px;
  color: theme.$onPrimary_colorBgMain;
  margin-bottom: 32px;
}
