@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

:where(.container) {
  all: unset;
  display: inline-block;
}

.container {
  cursor: pointer;
  background-color: transparent;
  color: theme.$colorFgMain;
  border-color: transparent;
  outline-color: theme.$colorFgMain;
  border-radius: 4px;

  transition-duration: 125ms;
  transition-property: background-color, color, border-color, outline-width,
    opacity, transform;
  text-underline-position: under;

  &:hover {
    color: theme.$colorLnHover;

    text-decoration: underline;
  }

  &:focus-visible {
    color: theme.$colorLnHover;
    outline: 2px solid theme.$colorFgMain;

    text-decoration: underline;
  }

  &:active {
    color: theme.$colorLnActive;
  }

  &:disabled {
    color: theme.$colorLnDisabled;
    cursor: not-allowed;
  }
}

.underline {
  text-decoration: underline;
}

.icon {
  font-size: 24px;
}
