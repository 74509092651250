@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.buttonWallet {
  all: unset;
  display: flex;

  cursor: pointer;
  color: theme.$colorFgMain;
  align-items: center;
  justify-content: space-between;

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-family: fonts.$sans;
  border: 1px solid theme.$colorFgDivider;
  text-decoration: none;
  padding: 21px 32px;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
  outline-color: theme.$colorFgMain;
  transition-property: background-color;
  transition-duration: 250ms;

  &:hover {
    background-color: theme.$colorBgHover;
  }

  &:focus-visible {
    background-color: theme.$colorBgHover;
    outline: 2px solid theme.$colorFgMain;
  }

  &:active {
    background: theme.$colorBgActive;
  }
}

.link {
  filter: grayscale(80%) opacity(50%);
}
