@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.title {
  color: theme.$colorFgMain;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.buttonClose {
  all: unset;
  display: flex;

  color: theme.$colorFgMain;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 250ms;

  &:hover {
    background-color: theme.$colorBgHover;
  }

  &:focus-visible {
    background-color: theme.$colorBgHover;
    outline: 2px solid theme.$colorFgMain;
  }

  &:active {
    background-color: theme.$colorBgActive;
  }
}

.buttonViewMore {
  font-size: 14px;
  font-weight: 500;
}
