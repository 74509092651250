@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.groupCategory {
  flex: 0 0 auto;
  font-family: fonts.$serif;
  font-style: italic;
}

.groupInfo {
  max-width: 100%;
  font-style: normal;
}

.groupActions {
  flex: 0 0 auto;
}

.collectionName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colorFgPrimary {
  color: theme.$colorFgPrimary;
}

.opacity_16 {
  opacity: 16%;
}

.opacity_64 {
  opacity: 64%;
}

.buttonNavigate {
  opacity: 16%;

  .container:hover & {
    opacity: 100%;
  }
}
