@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  align-items: center;
  color: theme.$colorFgMain;
  display: flex;
  font-family: fonts.$serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  gap: 12px;
  justify-content: center;
  line-height: 125%;
  padding: 40px 56px 0 56px;
}

.slot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.children {
  flex: 1 1 0;
}
