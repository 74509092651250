@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;

.container {
  display: flex;
  background-color: colors.$black80;

  &.sizingFill {
    position: absolute;
    inset: 0;
  }

  &.sizingAutoHeight {
    position: relative;
    width: 100%;

    &::after {
      content: "";
      display: flex;
      width: 100%;
      padding-top: calc(100% / var(--aspect-ratio, 1));
    }
  }

  &.noImage {
    background-color: colors.$grayOpaque30;
  }

  &.hasError {
    background-color: colors.$grayOpaque10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: colors.$black30;
  }
}

.iconContainer {
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 32px;
}
