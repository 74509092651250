@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.message {
  font-family: fonts.$fontSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: theme.$colorFgMain;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
