@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.content {
  background-color: theme.$colorBgSurface;
  border-radius: 4px;
  box-shadow: theme.$shadow;
  color: theme.$colorFgMain;
  max-height: var(--radix-dropdown-menu-content-available-height);
  min-width: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: variables.$zIndexDropdown;
  @include mixins.hideScrollBar();
}

.items {
  overflow: hidden;
  border-radius: 4px;
}

.arrow {
  fill: theme.$colorBgSurface;
}
