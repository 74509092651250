@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.buttonColor {
  background-color: theme.$onNavbar_colorBgMain;
  color: theme.$onNavbar_colorFgMain;
  border-color: theme.$onNavbar_colorFgBorder;

  &:hover {
    background-color: theme.$onNavbar_colorBgHover;
    color: theme.$onNavbar_colorFgHover;
  }

  &:focus-visible {
    outline-color: theme.$onNavbar_colorFgMain;
  }

  &:active {
    background-color: theme.$onNavbar_colorBgActive;
    color: theme.$onNavbar_colorFgActive;
  }

  &:disabled {
    background-color: theme.$onNavbar_colorBgDisabled;
    color: theme.$onNavbar_colorFgDisabled;
  }
}
