@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "sass:color" as color;

.menuContent {
  background-color: theme.$colorBgSurface;
  border-radius: 4px;
  box-shadow: theme.$shadow;
  color: theme.$colorFgMain;
  overflow: hidden auto;
  padding: 6px 0;
  width: 240px;
  z-index: variables.$zIndexDropdown;
  display: flex;
  flex-direction: column;
}

.menuItem {
  all: unset;
  display: block;

  cursor: pointer;
  font-family: fonts.$fontSans;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  padding: 12px 24px;
  transition-property: background-color, color;
  transition-duration: 250ms;

  &[data-highlighted] {
    background-color: theme.$colorBgSelected;
  }

  &[data-disabled] {
    color: theme.$colorFgDisabled;
  }
}

.menuArrow {
  fill: theme.$colorBgSurface;
}

.menuSeparator {
  border-top: 1px solid theme.$colorFgDivider;
  margin: 8px 0;
}

.menuLabel {
  all: unset;
  display: block;

  font-family: fonts.$fontSans;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  padding: 12px 24px;
  color: theme.$colorFgDimmed;
  text-transform: uppercase;
}
