@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.buttonColor {
  @include mixins.setColorNormal(transparent, colors.$black);
  @include mixins.setColorHover(colors.$black16, colors.$black);
  @include mixins.setColorActive(colors.$black32, colors.$black);
  @include mixins.setColorDisabled(transparent, colors.$black48);
}

.buttonSize {
  height: auto;
  width: auto;
}

.buttonPadding {
  padding: 8px;
  margin: -8px;
}
