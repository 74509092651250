@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "sass:color" as color;

.container {
  all: unset;
  display: inline-flex;

  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  transition-duration: 125ms;
  transition-property: background-color, color, border-color, outline-width,
    opacity, transform;
  user-select: none;

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

// color: "primary" | "secondary"

.colorPrimary {
  color: theme.$onPrimary_colorFgMain;
  background-color: theme.$onPrimary_colorBgMain;
  border-color: transparent;
  outline-color: theme.$colorFgMain;

  &:hover,
  &:focus-visible {
    background-color: theme.$onPrimary_colorBgHover;
  }

  &:active {
    background-color: theme.$onPrimary_colorBgActive;
  }

  &:disabled {
    background-color: theme.$onPrimary_colorBgDisabled;
    color: theme.$onPrimary_colorFgDisabled;
  }
}

.colorSecondary {
  color: theme.$onSecondary_colorFgMain;
  background-color: theme.$onSecondary_colorBgMain;
  border-color: transparent;
  outline-color: theme.$colorFgMain;

  &:hover,
  &:focus-visible {
    background-color: theme.$onSecondary_colorBgHover;
  }

  &:active {
    background-color: theme.$onSecondary_colorBgActive;
  }

  &:disabled {
    background-color: theme.$onSecondary_colorBgDisabled;
    color: theme.$onSecondary_colorFgDisabled;
  }
}

// border: "none" | "solid"

.borderNone {
  border-style: none;
  border-width: 0;
}

.borderSolid {
  border-style: solid;
  border-width: 1px;
}

// size

.sizeXs {
  height: 32px;
  width: auto;
}

.sizeXsSquare {
  height: 32px;
  width: 32px;
}

.sizeSm {
  height: 40px;
  width: auto;
}

.sizeSmSquare {
  height: 40px;
  width: 40px;
}

.sizeMd {
  height: 48px;
  width: auto;
}

.sizeMdSquare {
  height: 48px;
  width: 48px;
}

.sizeLg {
  height: 56px;
  width: auto;
}

.sizeLgSquare {
  height: 56px;
  width: 56px;
}

.sizeXl {
  height: 64px;
  width: auto;
}

.sizeXlSquare {
  height: 64px;
  width: 64px;
}

// padding: "medium" | "small" | "minimal"

.paddingMedium {
  padding: 4px 24px;
}

.paddingSmall {
  padding: 4px 16px;
}

.paddingMinimal {
  padding: 4px;
}

.content {
  align-items: center;
  display: flex;
  font-family: fonts.$fontSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  justify-content: center;
  line-height: 150%;
  text-transform: uppercase;
  position: relative;
  min-width: 0;
}

.children {
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 1em;

  .loading & {
    visibility: hidden;
  }
}

.icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  font-size: 24px;
  justify-content: center;

  .loading & {
    visibility: hidden;
  }
}

.circularProgressContainer {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
