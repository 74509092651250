.container {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.displayNameContainer {
  display: block;

  flex: 0 1 auto;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  display: flex;
  flex: 0 0 auto;
  min-width: auto;
  font-size: 150%;
  margin: -0.25em 0;
}
