@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  align-items: center;
  background-color: theme.$colorBgMain;
  border-top: 1px solid theme.$colorFgDivider;
  bottom: 0;
  display: flex;
  gap: 16px;
  height: auto;
  justify-content: flex-end;
  left: 0;
  padding: 16px 56px;
  position: sticky;
  right: 0;
}
