@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  padding: 40px 56px 0 56px;
}

.title {
  color: theme.$colorFgMain;
  font-family: fonts.$serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 125%;
}

.description {
  margin-top: 12px;
  color: theme.$colorFgDimmed;
  font-size: 16px;
  font-family: fonts.$sans;
  font-weight: 500;
  line-height: 175%;
}
