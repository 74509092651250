@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "sass:color" as color;

.container {
  color: theme.$onNavbar_colorFgMain;
  font-family: fonts.$sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.content {
  background-color: theme.$colorBgSurface;
  border-radius: 4px;
  box-shadow: theme.$shadow;
  color: theme.$colorFgMain;
  overflow: hidden auto;
  padding: 6px 0;
  width: 240px;
  z-index: variables.$zIndexDropdown;
  display: flex;
  flex-direction: column;
}

.label {
  font-family: fonts.$serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  font-style: italic;
}

.arrow {
  fill: theme.$colorBgSurface;
}

.item {
  all: unset;
  display: block;

  cursor: pointer;
  font-family: fonts.$fontSans;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  padding: 12px 24px;
  transition-property: background-color, color;
  transition-duration: 250ms;

  &[data-highlighted] {
    background-color: theme.$colorBgSelected;
  }
}
