@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.buttonColor {
  color: theme.$colorFgMain;
  background-color: transparent;
  border-color: theme.$colorFgDivider;

  &:hover,
  &:focus-visible {
    background-color: theme.$colorBgSelected;
  }

  &:active {
    // TODO: @kien-kreate: this color must be different from :hover
    background-color: theme.$colorBgSelected;
  }

  &:disabled {
    background-color: transparent;
    color: theme.$colorFgDisabled;
  }
}
