.bullet {
  flex: 0 0 auto;
}

.verticalLine {
  border-left: 1px solid currentColor;
  box-sizing: border-box;
  flex: 0 0 auto;
  height: 0.75em;
  width: 1px;
}
