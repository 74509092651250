@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.fusedButtonLeft > button {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-right: 16px !important;
}

.fusedButtonRight > button {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 16px !important;
}

.divider {
  align-self: stretch;
  width: 1px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.dividerContent {
  width: 1px;
  height: auto;
  background-color: theme.$onNavbar_colorFgMain;
  opacity: 16%;
  box-sizing: border-box;
  margin: 0px 0;
}
