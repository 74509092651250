.container {
  position: relative;

  &.paddingSmall {
    padding: 24px 32px;
  }

  &.paddingMedium {
    padding: 32px 56px;
  }
}
