@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.colorPrimary {
  color: colors.$primary;
}

.opacity_16 {
  opacity: 16%;
}

.groupCategory {
  font-family: fonts.$serif;
  font-style: italic;
}

.groupInfo {
  .verticalDivider {
    border-left: 1px solid currentColor;
    box-sizing: border-box;
    height: 12px;
    width: 1px;
  }

  em {
    font-family: fonts.$serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.groupActions {
  em {
    font-family: fonts.$serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.buttonNavigate {
  opacity: 16%;

  .container:hover & {
    opacity: 100%;
  }
}
