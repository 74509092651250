@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  align-items: center;
  border-bottom: 1px solid theme.$colorFgDivider;
  box-sizing: border-box;
  color: theme.$colorFgMain;
  cursor: pointer;
  display: flex;
  filter: brightness(1);
  font-family: fonts.$fontSans;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-start;
  line-height: 150%;
  min-height: 64px;
  outline: none;
  padding: 8px 24px;
  text-overflow: ellipsis;
  transition-duration: 125ms;
  transition-property: background-color, color, filter;

  &:last-child {
    border-bottom: none;
  }

  &[data-highlighted] {
    background-color: theme.$colorBgSelected;
  }

  &[data-disabled] {
    filter: grayscale(80%) opacity(50%);
    cursor: not-allowed;
  }
}

.content {
  align-items: center;
  display: flex;
  font-family: fonts.$fontSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 12px;
  justify-content: flex-start;
  line-height: 150%;
}

.children {
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 2em;
}

.icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  font-size: 24px;
  justify-content: center;
}
